import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a><h2>Prestige Classes</h2>
    <a id="duelist"></a><h3>DUELIST</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/FR_ART/People/ElaithCraulnober.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit Die</b>:
d10.</p>
    <a id="duelist-requirements"></a><h6>Requirements</h6>
    <p className="initial">To qualify to become a duelist, a character
must fulfill all the following criteria.</p>
    <p><b>Base Attack Bonus</b>: +6.</p>
    <p><b>Skills</b>: Perform 3 ranks, Tumble 5 ranks.</p>
    <p><b>Feats</b>: Dodge, Mobility, Weapon Finesse.</p>
    <h6>Class Skills</h6>
    <p className="initial">The duelist&#8217;s class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#balance">Balance</a>
(Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#bluff">Bluff</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#escape-artist">Escape Artist</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
(Str), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#perform">Perform</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sense-motive">Sense Motive</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a>
(Wis), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#tumble">Tumble</a> (Dex).</p>
    <p><b>Skill Points at Each Level</b>: 4 + Int modifier.</p>
    <a id="table-the-duelist"></a><p><b>Table: The Duelist</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "8%"
          }}>Level</th>
          <th style={{
            "width": "15%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "8%"
          }}>Fort
Save</th>
          <th style={{
            "width": "8%"
          }}>Ref
Save</th>
          <th style={{
            "width": "8%"
          }}>Will
Save</th>
          <th>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+1</td>
          <td>+0</td>
          <td>+2</td>
          <td>+0</td>
          <td>Canny defense</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+2</td>
          <td>+0</td>
          <td>+3</td>
          <td>+0</td>
          <td>Improved reaction +2</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+3</td>
          <td>+1</td>
          <td>+3</td>
          <td>+1</td>
          <td>Enhanced mobility</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+4</td>
          <td>+1</td>
          <td>+4</td>
          <td>+1</td>
          <td>Grace</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+5</td>
          <td>+1</td>
          <td>+4</td>
          <td>+1</td>
          <td>Precise strike +1d6</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+6</td>
          <td>+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>Acrobatic charge</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+7</td>
          <td>+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>Elaborate parry</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+8</td>
          <td>+2</td>
          <td>+6</td>
          <td>+2</td>
          <td>Improved reaction +4</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+9</td>
          <td>+3</td>
          <td>+6</td>
          <td>+3</td>
          <td>Deflect Arrows</td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+10</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">Precise strike +2d6</td>
        </tr>
      </tbody>
    </table>
    <a id="duelist-class-features"></a><h6>Class Features</h6>
    <p><b>Weapon and Armor Proficiency</b>: The duelist is proficient
with all simple and martial weapons, but no type of armor or shield.</p>
    <a id="duelist-canny-defense"></a><p><b>Canny Defense (Ex)</b>: When not wearing armor or using a
shield, a duelist adds 1 point of Intelligence bonus (if any) per
duelist class level to her Dexterity bonus to modify Armor Class while
wielding a melee weapon. If a duelist is caught flat-footed or
otherwise denied her Dexterity bonus, she also loses this bonus.</p>
    <a id="duelist-improved-reaction"></a><p><b>Improved Reaction (Ex)</b>: At 2nd level, a duelist gains a
+2 bonus on initiative checks.</p>
    <p>At 8th level, the bonus increases to +4. This bonus stacks
with the benefit provided by the Improved Initiative feat.</p>
    <a id="duelist-enhanced-mobility"></a><p><b>Enhanced Mobility (Ex)</b>: When wearing no armor and not
using a shield, a duelist gains an additional +4 bonus to AC against
attacks of opportunity caused when she moves out of a threatened square.</p>
    <a id="duelist-grace"></a><p><b>Grace (Ex)</b>: At 4th level, a duelist gains an additional
+2 competence bonus on all Reflex saving throws. This ability functions
for a duelist only when she is wearing no armor and not using a shield.</p>
    <a id="duelist-precise-strike"></a><p><b>Precise Strike (Ex)</b>: At 5th level, a duelist gains the
ability to strike precisely with a light or one-handed piercing weapon,
gaining an extra 1d6 damage added to her normal damage roll.</p>
    <p>When making a precise strike, a duelist cannot attack with a
weapon in her other hand or use a shield. A duelist&#8217;s precise strike
only works against living creatures with discernible anatomies. Any
creature that is immune to critical hits is not vulnerable to a precise
strike, and any item or ability that protects a creature from critical
hits also protects a creature from a precise strike. At 10th level, the
extra damage on a precise strike increases to +2d6.</p>
    <a id="duelist-acrobatic-charge"></a><p><b>Acrobatic Charge (Ex)</b>: At 6th level, a duelist gains
the ability to charge in situations where others cannot. She may charge
over difficult terrain that normally slows movement. Depending on the
circumstance, she may still need to make appropriate checks to
successfully move over the terrain.</p>
    <a id="duelist-elaborate-parry"></a><p><b>Elaborate Parry (Ex)</b>: At 7th level and higher, if a
duelist chooses to fight defensively or use total defense in melee
combat, she gains an additional +1 dodge bonus to AC for each level of
duelist she has.</p>
    <a id="duelist-deflect-arrows"></a><p><b>Deflect Arrows</b>: At 9th level, a duelist gains the
benefit of the Deflect Arrows feat when using a light or one-handed
piercing weapon.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      